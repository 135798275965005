import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './LessonPlanner.css'; 
import Modal from './AddTopicModal'; 
import * as XLSX from 'xlsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const LessonPlanner = () => {
    const [regions, setRegions] = useState([]);
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [topics, setTopics] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newTopic, setNewTopic] = useState({ topic_name: '', no_of_sessions: '' });
    const [message, setMessage] = useState('');
    const [selectedRegionName, setSelectedRegionName]=useState('');
    const [selectedSchoolName, setSelectedSchoolName]=useState('');
    const [selectedClassName, setSelectedClassName]=useState('');

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);  // <-- Pagination State
    const topicsPerPage = 7;  // Number of topics per page

    useEffect(() => {
        // Fetch regions
        axios.get(`${process.env.REACT_APP_API_URL}/regions`)
            .then(response => setRegions(response.data))
            .catch(error => console.error('Failed to fetch regions:', error));
    }, []);

    const handleDownloadExcel = () => {
        const worksheetData = [
            ["Region Name", selectedRegionName],  // Add Region Name
            ["School Name", selectedSchoolName],  // Add School Name
            ["Class Name", selectedClassName],    // Add Class Name
            [],                                   // Empty row to separate header from data
            ["S.No.", "Class Name", "Topic Name", "No. of sessions required"]  // Header Row
        ];
    
        topics.forEach((topic, index) => {
            worksheetData.push([
                index + 1,
                selectedClassName,         // Use Class Name
                topic.topic_name,
                topic.no_of_sessions
            ]);
        });
    
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Lesson Planner');
        XLSX.writeFile(workbook, `Lesson_Planner_${selectedClassName}.xlsx`);
    };

    const handleRegionChange = (e) => {
        setSelectedRegion(e.target.value);
        setSchools([]);
        setClasses([]);
        setTopics([]); // Clear topics when region is changed
        setSelectedSchool('');
        setSelectedClass('');
        setCurrentPage(1); // Reset to first page when region changes
        setSelectedRegionName(e.target.options[e.target.selectedIndex].text);

        if (e.target.value) {
            // Fetch schools based on selected region
            axios.get(`${process.env.REACT_APP_API_URL}/schools?region_id=${e.target.value}`)
                .then(response => setSchools(response.data))
                .catch(error => console.error('Failed to fetch schools:', error));
        }
    };

    const handleSchoolChange = (e) => {
        setSelectedSchool(e.target.value);
        setClasses([]);
        setTopics([]); // Clear topics when school is changed
        setSelectedClass('');
        setCurrentPage(1); // Reset to first page when school changes
        setSelectedSchoolName(e.target.options[e.target.selectedIndex].text);

        if (e.target.value) {
            // Fetch classes based on selected school
            axios.get(`${process.env.REACT_APP_API_URL}/classes?school_id=${e.target.value}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Failed to fetch classes:', error));
        }
    };

    const handleClassChange = (e) => {
        setSelectedClass(e.target.value);
        setTopics([]); // Clear topics when class is changed
        setCurrentPage(1); // Reset to first page when class changes
        setSelectedClassName(e.target.options[e.target.selectedIndex].text);

        if (e.target.value) {
            // Fetch topics based on selected class
            axios.get(`${process.env.REACT_APP_API_URL}/topics?class_id=${e.target.value}`)
                .then(response => setTopics(response.data))
                .catch(error => console.error('Failed to fetch topics:', error));
        }
    };

    const handleAddTopic = () => {
        axios.post(`${process.env.REACT_APP_API_URL}/topics`, {
            ...newTopic,
            class_id: selectedClass
        })
            .then(response => {
                setTopics([...topics, response.data.topic]);
                setMessage('Topic created successfully');
                setShowModal(false);
                setNewTopic({ topic_name: '', no_of_sessions: '' });
                setTimeout(() => setMessage(''), 5000);
            })
            .catch(error => {
                console.error('Failed to add topic:', error);
                setMessage('Failed to create topic. Please try again.');
                setTimeout(() => setMessage(''), 5000);
            });
    };

    const handleDeleteTopic = (topic_id) => {
        const confirmation = window.confirm("Deleting topics will also delete the work report records of this topic. Are you sure?");
        if (confirmation) {
            axios.delete(`${process.env.REACT_APP_API_URL}/topics/${topic_id}`)
                .then(response => {
                    setTopics(topics.filter(topic => topic.topic_id !== topic_id));
                    setMessage('Topic deleted successfully');
                    setTimeout(() => setMessage(''), 5000);
                })
                .catch(error => {
                    console.error('Failed to delete topic:', error);
                    if (error.response && error.response.status === 400) {
                        setMessage(error.response.data.message);
                    } else {
                        setMessage('Failed to delete topic. Please try again.');
                    }
                    setTimeout(() => setMessage(''), 5000);
                });
        }
    };

    // Calculate the current topics to display
    const indexOfLastTopic = currentPage * topicsPerPage;
    const indexOfFirstTopic = indexOfLastTopic - topicsPerPage;
    const currentTopics = topics.slice(indexOfFirstTopic, indexOfLastTopic); // <-- Current topics to display

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber); // <-- Function to change page

    return (
        <div className="lesson-planner-container">
            <h1>Lesson Planner</h1>
            {message && <div className="message-box">{message}</div>}
            <div className="dropdown-row">
                <select onChange={handleRegionChange} value={selectedRegion}>
                    <option value="">Select Region</option>
                    {regions.map(region => (
                        <option key={region.region_id} value={region.region_id}>
                            {region.region_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleSchoolChange} value={selectedSchool} disabled={!selectedRegion}>
                    <option value="">Select School</option>
                    {schools.map(school => (
                        <option key={school.school_id} value={school.school_id}>
                            {school.school_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleClassChange} value={selectedClass} disabled={!selectedSchool}>
                    <option value="">Select Class</option>
                    {classes.map(cls => (
                        <option key={cls.class_id} value={cls.class_id}>
                            {cls.class_name}
                        </option>
                    ))}
                </select>
            </div>

            <div className="buttons-row">
                <button className="add-topic-button" onClick={() => setShowModal(true)}>Add Topic</button>
                <button className="excel-button" onClick={handleDownloadExcel}>
                    <span><FontAwesomeIcon icon={faDownload} /></span> Excel
                </button>
            </div>

            <div className="table-wrapper">
                <table className="topics-table">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Class Name</th>
                            <th>Topic Name</th>
                            <th>No. of sessions required</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentTopics.map((topic, index) => (  // <-- Display only current page topics
                            <tr key={topic.topic_id}>
                                <td>{indexOfFirstTopic + index + 1}</td>
                                <td>{classes.find(cls => cls.class_id === topic.class_id)?.class_name || selectedClass}</td>
                                <td className={topic.topic_name.length > 20 ? 'long-topic' : ''}>{topic.topic_name}</td>
                                <td>{topic.no_of_sessions}</td>
                                <td>
                                    <button className="delete-button" onClick={() => handleDeleteTopic(topic.topic_id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className="pagination">  {/* <-- Pagination controls */}
                {[...Array(Math.ceil(topics.length / topicsPerPage)).keys()].map(number => (
                    <button
                        key={number + 1}
                        onClick={() => paginate(number + 1)}
                        className={`pagination-button ${currentPage === number + 1 ? 'active' : ''}`}
                    >
                        {number + 1}
                    </button>
                ))}
            </div>

            {showModal && (
                <Modal onClose={() => setShowModal(false)}>
                    <h2>Add Topic</h2>
                    <input
                        type="text"
                        placeholder="Enter topic name"
                        value={newTopic.topic_name}
                        onChange={(e) => setNewTopic({ ...newTopic, topic_name: e.target.value })}
                    />
                    <input
                        type="number"
                        placeholder="Enter no. of sessions for topic"
                        value={newTopic.no_of_sessions}
                        onChange={(e) => setNewTopic({ ...newTopic, no_of_sessions: e.target.value })}
                    />
                    <button onClick={handleAddTopic}>Save</button>
                    <button onClick={() => setShowModal(false)}>Close</button>
                </Modal>
            )}
        </div>
    );
};

export default LessonPlanner;
