import React,{useState, useEffect, useContext} from "react";
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import './RoboticsDateRangeWorkReport.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload,faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { addDays,format,formatISO, set } from 'date-fns';
import { DateRangePicker } from 'react-date-range';
import { useMediaQuery } from 'react-responsive';
import Loader from '../components/Loader';

const RoboticsDateRangeWorkReport= ()=>{
    const isMobile = useMediaQuery({ maxWidth: 768 });
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [message, setMessage] = useState('');
    const [regions, setRegions] = useState([]);
    const [schools, setSchools] = useState([]);
    const [classes, setClasses] = useState([]);
    const [batches, setBatches] = useState([]);
    const [topics, setTopics] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedSchool, setSelectedSchool] = useState('');
    const [selectedClass, setSelectedClass] = useState('');
    const [selectedBatch, setSelectedBatch] = useState('');
    const [selectedSchoolName, setSelectedSchoolName]=useState('');
    const [selectedClassName, setSelectedClassName]=useState('');
    const [selectedRegionName, setSelectedRegionName]=useState('');
    const [selectedBatchName, setSelectedBatchName]=useState('');
    const {userDetails} = useContext(AuthContext);
    const [reports, setReports] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dateState, setDateState] = useState([
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 7),
          key: 'selection'
        }
      ]);

    useEffect(() => {
        if (message) {
            const timer = setTimeout(() => {
                setMessage('');
            }, 5000);  
            return () => clearTimeout(timer);
        }
    }, [message]);

    // Effect for fetching regions based on user role
    useEffect(() => {
        const fetchRegions = async () => {
            try {
                let url = `${process.env.REACT_APP_API_URL}/regions`;
                console.log("User Role:", userDetails.role);  // Debugging
                console.log("Assigned Regions:", userDetails.assignedRegion);  // Debug
                if (userDetails.role === 'VIEWER' && userDetails.assignedRegion.length >=0) {
                    url += `?ids=${userDetails.assignedRegion.join(',')||null}`;
                }
                const response = await axios.get(url);
                setRegions(response.data);
            } catch (error) {
                console.error('Failed to fetch regions:', error);
            }
        };
        fetchRegions();
    }, [userDetails.role, userDetails.assignedRegion]);

    // Effect for fetching schools based on selected region
    useEffect(() => {
        if (selectedRegion) {
            const fetchSchools = async () => {
                try {
                    let url = `${process.env.REACT_APP_API_URL}/schools?region_id=${selectedRegion}`;
                    if (userDetails.role === 'VIEWER' && userDetails.assignedSchool.length > 0) {
                        url += `&ids=${userDetails.assignedSchool.join(',')}`;
                    }
                    const response = await axios.get(url);
                    setSchools(response.data);
                } catch (error) {
                    console.error('Failed to fetch schools:', error);
                }
            };
            fetchSchools();
        }
    }, [selectedRegion, userDetails.role, userDetails.assignedSchool]);

    useEffect(() => {
        if (selectedSchool) {
            axios.get(`${process.env.REACT_APP_API_URL}/classes?school_id=${selectedSchool}`)
                .then(response => setClasses(response.data))
                .catch(error => console.error('Failed to fetch classes:', error));
        }
    }, [selectedSchool]);

    useEffect(() => {
        if (selectedClass) {
            axios.get(`${process.env.REACT_APP_API_URL}/batches?class_id=${selectedClass}`)
                .then(response => setBatches(response.data))
                .catch(error => console.error('Failed to fetch batches:', error));
        }
    }, [selectedClass]);

    useEffect(() => {
        if (selectedBatch) {
            axios.get(`${process.env.REACT_APP_API_URL}/topics?class_id=${selectedClass}&batch_id=${selectedBatch}`)
                .then(response => setTopics(response.data))
                .catch(error => console.error('Failed to fetch topics:', error));
        }
    }, [selectedBatch, selectedClass]);

    // Fetch reports
useEffect(() => {
    setIsLoading(true);
    if (selectedBatch && dateState[0].startDate && dateState[0].endDate) {
        const { startDate, endDate } = dateState[0];
        const formattedStartDate = formatISO(startDate, { representation: 'date' });
        const formattedEndDate = formatISO(endDate, { representation: 'date' });

        console.log("Fetching reports for dates:", formattedStartDate, "to", formattedEndDate);  // Debugging output

        axios.get(`${process.env.REACT_APP_API_URL}/robotics-work-reports-date-range`, {
            params: {
                batch_id: selectedBatch,
                startDate: formattedStartDate,
                endDate: formattedEndDate
            }
        }).then(response => {
            const organizedData = organizeData(response.data);
            setReports(organizedData);
            setIsLoading(false);
            console.log("Reports fetched:", organizedData);  // Debugging output
        }).catch(error => {
            console.error('Failed to fetch reports:', error);
            setIsLoading(false);
        });
    }
}, [selectedBatch, dateState]); 
    // Organize reports data by date and topics
    const organizeData = (data) => {
        let organized = {};
        data.forEach(report => {
            const dateKey = format(new Date(report.date), 'yyyy-MM-dd');
            if (!organized[dateKey]) organized[dateKey] = {};
            organized[dateKey][report.topic_id] = report.description || '-';
        });
        return organized;
    };
    

    // Handler for Region Selection
    const handleRegionChange = (event) => {
        const regionId = event.target.value;
        const region = regions.find(r => r.region_id === regionId);
        setSelectedRegion(regionId);
        setSelectedRegionName(region ? region.region_name : '');
    };

    // Handler for Class Selection
    const handleClassChange = (event) => {
        const classId = event.target.value;
        const cls = classes.find(c => c.class_id === classId);
        setSelectedClass(classId);
        setSelectedClassName(cls ? cls.class_name : '');
    };

    // Handler for Batch Selection
    const handleBatchChange = (event) => {
        const batchId = event.target.value;
        const batch = batches.find(b => b.batch_id === batchId);
        setSelectedBatch(batchId);
        setSelectedBatchName(batch ? batch.batch_name : '');
    };

    // Handler for School Selection
    const handleSchoolChange = (event) => {
        const schoolId = event.target.value;
        const school = schools.find(s => s.school_id === schoolId);
        setSelectedSchool(schoolId);
        setSelectedSchoolName(school ? school.school_name : '');
    };

    const handleDownloadExcel = () =>{
        const worksheetData = [
            ["Region Name", selectedRegionName], // Using the state directly
            ["School Name", selectedSchoolName], // Using the state directly
            ["Class Name", selectedClassName],   // Using the state directly
            ["Batch Name", selectedBatchName],   // Using the state directly
            [],                                  // Empty row to separate header from data
            ["S.No.", "Date", ...topics.map(topic => topic.topic_name)]
        ];

        // Add rows for each date, including a serial number
        let serialNumber = 1; // Initialize serial number
        Object.entries(reports).forEach(([date, topicReports]) => {
            const row = [serialNumber++, date]; // Start each row with a serial number, increment after each row
            topics.forEach(topic => {
                row.push(topicReports[topic.topic_id] || '-'); // Push description or '-' if none exists
            });
            worksheetData.push(row);
        });

        // Convert the data to a worksheet
        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Reports');

        XLSX.writeFile(workbook, `Reports_${selectedBatchName}.xlsx`);
    }

    return(
        <div className="robotics-date-range-work-report">
            {message && (
                <div className="message-box">
                    <p>{message}</p>
                    <button onClick={()=>setMessage('')}>Close</button>
                </div>
            )}

            <h1>Work Report</h1>
            <div className="dropdown-row-RoboticsDateRangeWorkReport">
                <select onChange={handleRegionChange} value={selectedRegion}>
                    <option value="">Region</option>
                    {regions.map(region=>(
                        <option key={region.region_id} value={region.region_id}>
                            {region.region_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleSchoolChange} value={selectedSchool} disabled={!selectedRegion}>
                    <option value="">School</option>
                    {schools.map(school=>(
                        <option value={school.school_id} key={school.school_id}>
                            {school.school_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleClassChange} value={selectedClass} disabled={!selectedSchool}>
                    <option value="">Class</option>
                    {classes.map(cls =>(
                        <option key={cls.class_id} value={cls.class_id}>
                            {cls.class_name}
                        </option>
                    ))}
                </select>
                <select onChange={handleBatchChange} value={selectedBatch} disabled={!selectedClass}>
                    <option value="">Batch</option>
                    {batches.map(batch => (
                        <option key={batch.batch_id} value={batch.batch_id}>
                            {batch.batch_name}
                        </option>
                    ))}
                </select>
                <button onClick={() => setShowDatePicker(!showDatePicker)} className="toggle-date-picker">
                    <span><FontAwesomeIcon icon={faCalendarAlt} /></span>
                </button>
            </div>
            <div className="DatePicker">
            {showDatePicker && (
            <DateRangePicker
                    onChange={item => setDateState([item.selection])}
                    showSelectionPreview={true}
                    moveRangeOnFirstSelection={false}
                    months={isMobile ? 1 : 2}
                    ranges={dateState}
                    direction="horizontal"
                />
            )}
            </div>
            <div className="buttons-row-RoboticsDateRangeWorkReport">
                <button className="export-button-RoboticsDateRangeWorkReport" onClick={() => handleDownloadExcel()}>
                    <span><FontAwesomeIcon icon={faDownload}/></span>Excel
                </button>
            </div>

            {selectedBatch && (
                <div className="table-wrapper-RoboticsDateRangeWorkReport">
                    {isLoading?<Loader/>:
                <table className="reports-table-RoboticsDateRangeWorkReport">
                    <thead>
                        <tr>
                            <th>Date</th>
                            {topics.map(topic => (
                                <th key={topic._id}>{topic.topic_name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.entries(reports).map(([date, topicReports]) => (
                            <tr key={date}>
                                <td>{date}</td>
                                {topics.map(topic => (
                                    <td key={topic._id} className={topicReports[topic.topic_id] && topicReports[topic.topic_id].length > 20 ? 'long-topic-RoboticsDateRangeWorkReport' : ''}>{topicReports[topic.topic_id] || '-'}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                }
                </div>
            )}
        </div>
    );
};

export default RoboticsDateRangeWorkReport;