import React from 'react';
import './ClassAndBatchModal.css';

const ClassAndBatchModal = ({
  show,
  onClose,
  regions,
  selectedRegion,
  onRegionChange,
  schools,
  selectedSchool,
  onSchoolChange,
  classes,
  selectedClass,
  onClassChange,
  batches,
  handleDeleteClass,
  handleDeleteBatch,
  openAddClassModal,
  openAddBatchModal,
}) => {
  if (!show) return null;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        {/* Conditional rendering based on whether it's a class or batch modal */}
        <h2>{batches ? 'Manage Batch' : 'Manage Class'}</h2>

        <label>Select Region</label>
        <select value={selectedRegion} onChange={onRegionChange}>
          <option value="">Select Region</option>
          {regions.map((region) => (
            <option key={region.region_id} value={region.region_id}>
              {region.region_name}
            </option>
          ))}
        </select>

        <label>Select School</label>
        <select value={selectedSchool} onChange={onSchoolChange}>
          <option value="">Select School</option>
          {schools.map((school) => (
            <option key={school.school_id} value={school.school_id}>
              {school.school_name}
            </option>
          ))}
        </select>

        {/* Display class list if managing classes */}
        {!batches && (
          <>
            <h3 className='existing'>Existing Classes</h3>
            <ul className="class-list-manage">
              {classes.map((classItem) => (
                <li key={classItem.class_id}>
                  <span className="class-name">{classItem.class_name}</span>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteClass(classItem.class_id)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={openAddClassModal}>Add Class</button>
          </>
        )}

        {/* Display batch list if managing batches */}
        {batches && (
          <>
            <label>Select Class</label>
            <select value={selectedClass} onChange={onClassChange}>
              <option value="">Select Class</option>
              {classes.map((classItem) => (
                <option key={classItem.class_id} value={classItem.class_id}>
                  {classItem.class_name}
                </option>
              ))}
            </select>

            <h3 className='existing'>Existing Batches</h3>
            <p className='note'>Note: include academic year in bracket after batch name. E.g.: 1A (2024-25)</p>
            <ul className="class-list-manage">
              {batches.map((batchItem) => (
                <li key={batchItem.batch_id}>
                  <span className="class-name">{batchItem.batch_name}</span>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteBatch(batchItem.batch_id)}
                  >
                    Delete
                  </button>
                </li>
              ))}
            </ul>
            <button onClick={openAddBatchModal}>Add Batch</button>
          </>
        )}

        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ClassAndBatchModal;
