import React from 'react';

const ContactUs = () => {
  return (
    <div>
      <h2>Contact Us Page</h2>
      <p>Welcome to the Contact Us page.</p>
    </div>
  );
};

export default ContactUs;
