import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <div className="home">
      <h2>ABOUT US</h2>
      <p>
        Techvein was founded in 2015 with the purpose of changing the education
        industry and introducing a new set of standards. Our observations of
        education solutions around the globe prompted us to study mistakes and
        begin to design solutions. We observed an industry with some education
        solutions offering incredibly low pricing, but sub-standard service and
        support. On the contrary, we noticed other education solutions offering
        great service and support, but charging a premium price. The passion
        grew for us to improve how a education oriented company should operate.
        We became experts in this industry and learned what it truly costs to
        provide great education services. We took the best of two extremes and
        formed it into one great company. On that day, Techvein was born.
      </p>
    </div>
  );
};

export default Home;
