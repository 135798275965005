import React, { useContext,} from 'react';
import subjectPlannerImage from './subject-planner.webp';
import inventoryImage from './inventory.webp';
import syllabusImage from './syllabus.webp';
import lessonPlannerImage from './lesson-planner.webp';
import configureImage from './configure.webp';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import './Robotics.css';

const Robotics = () => {
  const { userDetails } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleCardClick = (cardTitle) => {
    if (cardTitle === "Configuration") {
      navigate('/manage-classes-batches');
    } else if(cardTitle==="Lesson Planner") {
      // Handle other card clicks if necessary
      navigate('/lesson-planner');
    }
    else if(cardTitle==="Work Report"){
      navigate('/report-view-work');
    }
  };

  console.log('User role:', userDetails.role);

  const functions = [
    {
        title: 'Configuration',
        description: 'Configure classes and batches for any particular School.',
        detailsButton: 'Configure',
        image: configureImage,
        adminOnly: true, // Add a flag to indicate that this card is for admins only
      },
    {
      title: 'Lesson Planner',
      description: 'Lesson Planner helps you manage, track and schedule topics across schools, for a well-organized educational experience.',
      detailsButton: 'Plan',
      image: lessonPlannerImage,
      adminOnly: true, // Add a flag to indicate that this card is for admins only
    },
    {
      title: 'Work Report',
      description: 'Work Report helps you report topics that has been taught across schools, and view past records for a well-organized educational experience.',
      detailsButton: 'Report',
      image: subjectPlannerImage,
      adminOnly: false,
    },
    {
      title: 'Inventory Manager',
      description: 'Inventory Manager streamlines the tracking and organization of robotics components, ensuring efficient management and easy access to parts for all your projects.',
      detailsButton: 'Manage',
      image: inventoryImage,
      adminOnly: true,
    },
    {
      title: 'Syllabus',
      description: 'Syllabus Management simplifies the organization and distribution of course content, ensuring that educators can efficiently plan, and update syllabus across all classes.',
      detailsButton: 'Check out',
      image: syllabusImage,
      adminOnly: false,
    },
  ];

  return (
    <div className="robotics-container">
      <h2>Robotics Functions</h2>
      <div className="cards-all">
        {functions.map((func, index) => (
          (userDetails.role === 'ADMIN' || !func.adminOnly) && (
          <div key={index} className="card">
            <div className="card-preview">
              <img src={func.image} alt={func.title} className="card-image" />
            </div>
            <div className="card-right">
              <div className="card-content">
                <h2>{func.title}</h2>
                <p className="description">{func.description}</p>
              </div>
              <div className="card-footer">
                <button className="details-button" onClick={() => handleCardClick(func.title)}>{func.detailsButton}</button>
              </div>
            </div>
          </div>
          )
        ))}
      </div>
    </div>
  );
};

export default Robotics;
