import React from 'react';
import './Modal.css';

const Modal = ({ show, onClose, onSave, title, placeholder }) => {
  if (!show) {
    return null;
  }

  let input;

  return (
    <div className="modal-backdrop">
      <div className="modal-content">
        <h2>{title}</h2>
        <input
          type="text"
          placeholder={placeholder}
          ref={(node) => (input = node)}
          className="modal-input"
        />
        <div className="modal-actions">
          <button onClick={() => onSave(input.value)} className="save-button">
            Save
          </button>
          <button onClick={onClose} className="cancel-button">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
