import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import './Auth.css';
const API_BASE_URL = process.env.REACT_APP_API_URL;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setAuth,setUserDetails } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${API_BASE_URL}/auth/login`, { email, password });
      // console.log("Token received on login:", res.data.token); // Debugging
      localStorage.setItem('token', res.data.token); // Store token in localStorage
      setAuth(true);
      await fetchUserDetails(res.data.token);
      navigate('/'); // Redirect to home page
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data.msg === 'Your account has been blocked. Please contact support.') {
        setError('Your account has been blocked. Please contact support.');
      } else {
        setError('Invalid username or password. Please try again.');
      }
    }
  };

  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/current-user`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setUserDetails(response.data); // Set user details from fetched data
    } catch (error) {
      console.error('Failed to fetch user details immediately after login:', error);
      setAuth(false);
    }
  };

  return (
    <div className="auth-container">
      <form onSubmit={handleSubmit}>
        <h2>Digital Valley</h2>
        {error && <p className="error">{error}</p>}
        <div className="inputbox">
          <input
            type="email"
            className="input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FontAwesomeIcon className="icons" icon={faUser} />
        </div>
        <div className="inputbox">
          <input
            type="password"
            className="input"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <FontAwesomeIcon className="icons" icon={faLock} />
        </div>
        <button type="submit" className="submit">Login</button>
        <div className="createnew">
          <span>Don't have an account? <Link className="register" to="/register">Register</Link></span>
        </div>
        <div className="createnew">
          <span><Link className="register" to="/forgot-password">Forgot Password?</Link></span>
        </div>
      </form>
    </div>
  );
};

export default Login;
