import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ManageClassesBatches.css';
import ClassAndBatchModal from './ClassAndBatchModal';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const ManageClassesBatches = () => {
  const [regions, setRegions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classes, setClasses] = useState([]);
  const [batches, setBatches] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedSchool, setSelectedSchool] = useState('');
  const [selectedClass, setSelectedClass] = useState('');
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [isBatchModalOpen, setIsBatchModalOpen] = useState(false);
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [newClassName, setNewClassName] = useState('');
  const [newBatchName, setNewBatchName] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchRegions();
  }, []);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchRegions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/regions`);
      setRegions(response.data);
    } catch (error) {
      console.error('Error fetching regions:', error);
      setMessage('Failed to fetch regions. Please try again.');
    }
  };

  const fetchSchools = async (regionId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/schools?region_id=${regionId}`);
      setSchools(response.data);
    } catch (error) {
      console.error('Error fetching schools:', error);
      setMessage('Failed to fetch schools. Please try again.');
    }
  };

  const fetchClasses = async (schoolId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/classes?school_id=${schoolId}`);
      setClasses(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setMessage('Failed to fetch classes. Please try again.');
    }
  };

  const fetchBatches = async (classId) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/batches?class_id=${classId}`);
      setBatches(response.data);
    } catch (error) {
      console.error('Error fetching batches:', error);
      setMessage('Failed to fetch batches. Please try again.');
    }
  };

  const handleRegionChange = (e) => {
    setSelectedRegion(e.target.value);
    fetchSchools(e.target.value);
  };

  const handleSchoolChange = (e) => {
    setSelectedSchool(e.target.value);
    fetchClasses(e.target.value);
  };

  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
    fetchBatches(e.target.value);
  };

  const openManageClassModal = () => {
    setIsClassModalOpen(true);
  };

  const openManageBatchModal = () => {
    setIsBatchModalOpen(true);
  };

  const openAddClassModal = () => {
    setIsChildModalOpen(true);
  };

  const openAddBatchModal = () => {
    setIsChildModalOpen(true);
  };

  const handleAddClass = async () => {
    try {
      const class_id = `${newClassName.replace(/\s+/g, '').toLowerCase()}_${uuidv4()}`;
      await axios.post(`${API_BASE_URL}/classes`, { class_name: newClassName, school_id: selectedSchool, class_id });
      fetchClasses(selectedSchool);
      setIsChildModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage('Class not created. ID already exists.');
      } else {
        setMessage('Failed to save class. Please try again.');
      }
    }
  };

  const handleAddBatch = async () => {
    try {
      const batch_id = `${newBatchName.replace(/\s+/g, '').toLowerCase()}_${uuidv4()}`;
      await axios.post(`${API_BASE_URL}/batches`, { batch_name: newBatchName, class_id: selectedClass, batch_id });
      fetchBatches(selectedClass);
      setIsChildModalOpen(false);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage('Batch not created. ID already exists.');
      } else {
        setMessage('Failed to save batch. Please try again.');
      }
    }
  };

  const handleDeleteClass = async (classId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/classes/${classId}`);
      fetchClasses(selectedSchool);
      setMessage(response.data.message); // Display success or error message
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage('Class cannot be deleted. Batches are present in this class.');
      } else {
        console.error('Error deleting class:', error);
        setMessage('Failed to delete class. Please try again.');
      }
    }
  };

  const handleDeleteBatch = async (batchId) => {
    try {
      const response = await axios.delete(`${API_BASE_URL}/batches/${batchId}`);
      fetchBatches(selectedClass);
      setMessage(response.data.message); // Display success or error message
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setMessage('Batch cannot be deleted.');
      } else {
        console.error('Error deleting batch:', error);
        setMessage('Failed to delete batch. Please try again.');
      }
    }
  };

  return (
    <div className="manage-container">
      <h1>Configure Classes and Batches</h1>
      <div className="manage-grid">
        <div className="manage-card" onClick={openManageClassModal}>
          <h3>Manage Class</h3>
          <p>Create and Delete class from here.</p>
        </div>

        <div className="manage-card" onClick={openManageBatchModal}>
          <h3>Manage Batch</h3>
          <p>Create and Delete batch from here.</p>
        </div>
      </div>

      <ClassAndBatchModal
        show={isClassModalOpen || isBatchModalOpen}
        onClose={() => {
          setIsClassModalOpen(false);
          setIsBatchModalOpen(false);
        }}
        regions={regions}
        selectedRegion={selectedRegion}
        onRegionChange={handleRegionChange}
        schools={schools}
        selectedSchool={selectedSchool}
        onSchoolChange={handleSchoolChange}
        classes={classes}
        selectedClass={selectedClass}
        onClassChange={handleClassChange}
        batches={isBatchModalOpen ? batches : null}
        handleDeleteClass={handleDeleteClass}
        handleDeleteBatch={handleDeleteBatch}
        openAddClassModal={openAddClassModal}
        openAddBatchModal={openAddBatchModal}
      />

      {/* Child Modal for Adding New Class */}
      {isChildModalOpen && (
        <div className="modal-backdrop">
          <div className="modal-content">
            <h2>{isClassModalOpen ? 'Add Class' : 'Add Batch'}</h2>
            <input
              type="text"
              value={isClassModalOpen ? newClassName : newBatchName}
              onChange={(e) => isClassModalOpen ? setNewClassName(e.target.value) : setNewBatchName(e.target.value)}
              placeholder={`Enter ${isClassModalOpen ? 'class' : 'batch'} name`}
            />
            <button onClick={isClassModalOpen ? handleAddClass : handleAddBatch}>Save</button>
            <button onClick={() => setIsChildModalOpen(false)}>Close</button>
          </div>
        </div>
      )}

      {/* Display Message */}
      {message && (
        <div className="message-box">
          <p>{message}</p>
          <button onClick={() => setMessage('')}>Close</button>
        </div>
      )}
    </div>
  );
};

export default ManageClassesBatches;
