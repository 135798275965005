import React, { useState, lazy, Suspense} from 'react';
import './ExcelConversions.css';

const ModalComponents = {
  'MergeExcel': lazy(() => import('./excel-conversion-modals/excel-merge-modal')),
  'CsvToExcel': lazy(() => import('./excel-conversion-modals/csv-to-excel-convert-modal')),
  'MonthlyFinanceReport': lazy(()=> import('./excel-conversion-modals/monthly-finance-report')),
  'AddTotalInMonthlyFinanceReport': lazy(()=> import('./excel-conversion-modals/add_total_monthly_finance_report'))
  // Add other modals similarly
};

const ExcelConversions = () => {

  const [modalShow, setModalShow] = useState(false);
  const [activeModal, setActiveModal] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  
  const handleSearch = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = functions.filter(func => func.title.toLowerCase().includes(searchValue));
    setFilteredFunctions(filtered);
  };
  

  const functions =[
    {
      title:"Merge Excel",
      description:"Merge two or more excel files with same headers. You can merge .xlsx, .xls and .csv files. You can upload maximum of 10MB files combined.",
      detailsButton:"Merge",
      modalKey: 'MergeExcel'
    },
    {
      title:"CSV to XLSX",
      description:"Convert one or multiple CSV file into XLSX format. You can upload maximum of 10MB files combined.",
      detailsButton:"Convert",
      modalKey: 'CsvToExcel'
    },
    {
      title:"Monthly Finance Report",
      description:"Upload all three files (Additional, Settlement, Settlement Recon) required for report generation and hit generate for magic.",
      detailsButton:"Generate",
      modalKey:'MonthlyFinanceReport'
    },
    {
      title:"Add Total In Monthly Finance Report",
      description:"You can upload here the ERP sheet from Monthly Finance Report to insert TOTAL rows in it.",
      detailsButton:"Insert",
      modalKey:'AddTotalInMonthlyFinanceReport'
    }
  ];
  const [filteredFunctions, setFilteredFunctions] = useState(functions);
  
  const handleOpenModal = (modalKey) => {
    setActiveModal(modalKey);
    setModalShow(true);
  };

  return (
    <div className="excel-conversion-container">
    <h2 className="header-xlsx">Excel Conversions</h2>
    <input 
        type="text" 
        className="search-bar" 
        placeholder="Search operations..." 
        value={searchTerm} 
        onChange={handleSearch}
      />
    <div className="grid-container-xlsx">
      {filteredFunctions.map((func, index) => (
        <div className="card-xlsx" key={index}>
          {/* <div className="card-img-xlsx"><img src={func.image} alt={func.title}></img>
          </div> */}
          <div className="card-body-xlsx">
            <div className="card-title-xlsx">{func.title}</div>
            <div className="card-text-xlsx">
              {func.description}
            </div>
            <button className="button button-outline-light" onClick={() => handleOpenModal(func.modalKey)}>{func.detailsButton}</button>
          </div>
        </div>
      ))}
    </div>
    {modalShow && activeModal && (
        <Suspense fallback={<div>Loading...</div>}>
          {React.createElement(ModalComponents[activeModal], {
            onClose: () => setModalShow(false)
          })}
        </Suspense>
      )}
    </div>
  );
};

export default ExcelConversions;
