import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import Loader from '../components/Loader';
import { AuthContext } from '../context/AuthContext';
import './ManageUsers.css';  // Assuming styles are stored here
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const ManageUsers = () => {
  const { auth, userDetails } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [regions, setRegions] = useState([]);
  const [schools, setSchools] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [selectedSchools, setSelectedSchools] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 7;
  // Calculate the current users to display
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    // Ensure the user is an admin before fetching users
    setIsLoading(true);
    if (auth && userDetails.role === 'ADMIN') {
      axios.get(`${process.env.REACT_APP_API_URL}/auth/users`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(response => {
        setUsers(response.data);
        setFilteredUsers(response.data); // Set initial filteredUsers to all users
        setIsLoading(false);
      })

        .catch(error => {
          console.error('Failed to fetch users:', error);
          setMessage('Failed to fetch users.');
          setIsLoading(false);
        });
    }
  }, [auth, userDetails.role]);

  const handleRoleChange = (userId, newRole) => {
    // Update the user's role in the backend
    axios.put(`${process.env.REACT_APP_API_URL}/auth/users/${userId}/role`, { role: newRole }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
      .then(response => {
        setUsers(users.map(user => user._id === userId ? { ...user, role: newRole } : user));
        setFilteredUsers(filteredUsers.map(user => user._id === userId ? { ...user, role: newRole } : user));
        setMessage('User role updated successfully.');
        setTimeout(() => setMessage(''), 3000);
      })
      .catch(error => {
        console.error('Failed to update user role:', error);
        setMessage('Failed to update user role.');
        setTimeout(() => setMessage(''), 3000);
      });
  };

  const handleStatusChange = (userId, newStatus) => {
    // Update the user's status in the backend
    axios.put(`${process.env.REACT_APP_API_URL}/auth/users/${userId}/status`, { status: newStatus }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      setUsers(users.map(user => user._id === userId ? { ...user, status: newStatus } : user));
      setFilteredUsers(filteredUsers.map(user => user._id === userId ? { ...user, status: newStatus } : user));
      setMessage('User status updated successfully.');
      setTimeout(() => setMessage(''), 3000);
    })
    .catch(error => {
      console.error('Failed to update user status:', error);
      setMessage('Failed to update user status.');
      setTimeout(() => setMessage(''), 3000);
    });
  };
  

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearchTerm(searchValue);
    const filtered = users.filter(user => 
      user.name.toLowerCase().includes(searchValue) || 
      user.email.toLowerCase().includes(searchValue) || 
      user.role.toLowerCase().includes(searchValue)
    );
    setFilteredUsers(filtered);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleAssignClick = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  
    // Clear previous selections
    setSelectedRegions([]);
    setSelectedSchools([]);
    setSchools([]);
  
    // Fetch regions
    axios.get(`${process.env.REACT_APP_API_URL}/regions`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    .then(response => {
      setRegions(response.data);
  
      // Fetch assigned regions and schools for the selected user
      axios.get(`${process.env.REACT_APP_API_URL}/auth/users/${user._id}/assignments`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then(assignResponse => {
        const assignedRegions = assignResponse.data.regions || [];
        const assignedSchools = assignResponse.data.schools || [];
  
        // Preselect assigned regions and schools
        setSelectedRegions(assignedRegions.map(region => region.region_id));
        setSelectedSchools(assignedSchools.map(school => school.school_id));
  
        // Fetch schools for the initially assigned regions
        const schoolFetchPromises = assignedRegions.map(regionId =>
          axios.get(`${process.env.REACT_APP_API_URL}/schools`, {
            params: { region_id: regionId.region_id },
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          })
        );
  
        // Resolve all school fetch promises
        Promise.all(schoolFetchPromises)
          .then(schoolResponses => {
            // Flatten all school responses into a single array and remove duplicates
            const allSchools = schoolResponses.flatMap(response => response.data);
            setSchools(prevSchools => [
              ...prevSchools,
              ...allSchools.filter(newSchool =>
                !prevSchools.some(existingSchool => existingSchool.school_id === newSchool.school_id)
              )
            ]);
          })
          .catch(error => {
            console.error('Failed to fetch schools:', error);
          });
      })
      .catch(error => {
        console.error('Failed to fetch assigned regions and schools:', error);
      });
    })
    .catch(error => {
      console.error('Failed to fetch regions:', error);
    });
  };
  
  

  const handleRegionChange = (regionId) => {
    const isSelected = selectedRegions.includes(regionId);
    
    setSelectedRegions(prev =>
      prev.includes(regionId) ? prev.filter(r => r !== regionId) : [...prev, regionId]
    );

    // If the region is being deselected, remove its schools from the list
    if (isSelected) {
      setSchools(prevSchools => prevSchools.filter(school => school.region_id !== regionId));
      setSelectedSchools(prevSelectedSchools => 
        prevSelectedSchools.filter(schoolId => 
          !schools.find(school => school.school_id === schoolId && school.region_id === regionId)
        )
      );
    } else {
    // Optionally, fetch and update schools when a region is selected/deselected
        axios.get(`${process.env.REACT_APP_API_URL}/schools`, {
          params: { region_id: regionId },
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        })
    .then(response => {
      /// Merge new schools into the existing list, avoiding duplicates
      setSchools(prevSchools => [
        ...prevSchools,
        ...response.data.filter(newSchool =>
          !prevSchools.some(existingSchool => existingSchool.school_id === newSchool.school_id)
        )
      ]);
    })
    .catch(error => {
      console.error('Failed to fetch schools:', error);
    });
  }
  };
  

  const handleSchoolChange = (school) => {
    setSelectedSchools(prev => 
      prev.includes(school) ? prev.filter(s => s !== school) : [...prev, school]
    );
  };

  const handleAssignSubmit = () => {
    // API call to assign selected regions and schools to the selected user
    axios.post(`${process.env.REACT_APP_API_URL}/auth/users/${selectedUser._id}/assign`, {
      regions: selectedRegions,
      schools: selectedSchools
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then(response => {
      // Update the user state or handle success
      setMessage('Regions and schools assigned successfully.');
      setShowModal(false);
    })
    .catch(error => {
      console.error('Failed to assign regions and schools:', error);
      setMessage('Failed to assign regions and schools.');
    });
  };

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage('');
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);


  return (
    <div className="manage-users-container">
      <h1>Manage Users</h1>
      {message && 
        <div className="message-box">
          <p>{message}</p>
          <button onClick={() => setMessage('')}>Close</button>
        </div>
      }

      <input 
        type="text" 
        className="search-bar" 
        placeholder="Search users..." 
        value={searchTerm} 
        onChange={handleSearch} 
      />
      {isLoading ? <Loader /> :
      <div className="table-wrapper">
      <table className="user-table">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Name</th>
            <th>Email</th>
            <th>Region / School</th>
            <th>Status</th>
            <th>Update Role</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user,index) => (
            <tr key={user._id}>
              <td>{indexOfFirstUser + index + 1}</td>
              <td className={user.name.length > 10 ? 'long-content' : '' } >{user.name}</td>
              <td className={user.email.length > 10 ? 'long-content' : ''}>{user.email}</td>
              <td className='plusIcon'>
                <button  onClick={() => handleAssignClick(user)}><FontAwesomeIcon icon={faPenToSquare} /></button>
              </td>
              <td>
                <select value={user.status} onChange={(e) => handleStatusChange(user._id, e.target.value)}>
                  <option value="ACTIVE">Active</option>
                  <option value="INACTIVE">Inactive</option>
                </select>
              </td>
              <td>
                <select value={user.role} onChange={(e) => handleRoleChange(user._id, e.target.value)}>
                  <option value="VIEWER">Viewer</option>
                  <option value="ADMIN">Admin</option>
                </select>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      }
      <div className="pagination">
                {[...Array(Math.ceil(filteredUsers.length / usersPerPage)).keys()].map(number => (
                    <button
                        key={number + 1}
                        onClick={() => setCurrentPage(number + 1)}
                        className={`pagination-button ${currentPage === number + 1 ? 'active' : ''}`}
                    >
                        {number + 1}
                    </button>
                ))}
        </div>

        {showModal && (
  <div className="modal-overlay-user">
    <div className="modal-content-user">
      <h2 className="modal-header-user">Assign Region / School</h2>

      <div className="region-container-user">
        {regions.map(region => (
          <div key={region.region_id}>
            <label>
              <input
                type="checkbox"
                checked={selectedRegions.includes(region.region_id)}
                onChange={() => handleRegionChange(region.region_id)}
              />
              {region.region_name}
            </label>

            {/* Display schools under the selected region */}
            {selectedRegions.includes(region.region_id) && (
              <ul className="school-list-user">
                {schools
                  .filter(school => school.region_id === region.region_id)
                  .map(school => (
                    <li key={school.school_id} className="school-items-user">
                      <label>
                        <input
                          type="checkbox"
                          checked={selectedSchools.includes(school.school_id)}
                          onChange={() => handleSchoolChange(school.school_id)}
                        />
                        {school.school_name}
                      </label>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        ))}
      </div>

      <div className="modal-footer-user">
        <button onClick={handleAssignSubmit}>Save</button>
        <button onClick={() => setShowModal(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}


    </div>
    
  );
};

export default ManageUsers;
