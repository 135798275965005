// src/components/Sidebar.js
import logo from './logo.png';
import React, { useContext,useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faHouse, faFileExcel, faSchool, faAddressBook, faRightFromBracket, faRobot, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import './Sidebar.css';


const Sidebar = () => {
  const { handleLogout, userDetails } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const logoutAndNavigate = () => {
    handleLogout();
    navigate('/login');
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleLinkClick = () => {
    setIsOpen(false); // This will close the sidebar
  };
  

  return (
    <>
      <input type="checkbox" id="checkbox" checked={isOpen} onChange={toggleSidebar} className="checkbox" />
      <label htmlFor="checkbox" className="toggle">
        <div className="bar bar--top"></div>
        <div className="bar bar--middle"></div>
        <div className="bar bar--bottom"></div>
      </label>
    <aside className={`sidebar ${isOpen ? 'open' : ''}`}>
      <div className="header">
        <img src={logo} alt='logo not found' />
        <h1>Techvein</h1>
      </div>
      <nav>
        <Link to="/profile" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faUser} />
          <p>Profile</p>
        </Link>
        <Link to="/" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faHouse} />
          <p>Home</p>
        </Link>
        <Link to="/excel-conversions" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faFileExcel} />
          <p>Excel Conversions</p>
        </Link>
        {userDetails.role === 'ADMIN' && (
          <Link to="/manage-users" className="nav-link" onClick={handleLinkClick}>
            <FontAwesomeIcon icon={faUserEdit} />
            <p>Manage Users</p>
          </Link>
        )}
        <Link to="/schools" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faSchool} />
          <p>Schools</p>
        </Link>
        <Link to="/robotics" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faRobot} />
          <p>Robotics</p>
        </Link>
        <Link to="/contact-us" className="nav-link" onClick={handleLinkClick}>
          <FontAwesomeIcon icon={faAddressBook} />
          <p>Contact Us</p>
        </Link>
        <a onClick={logoutAndNavigate} className="nav-link" >
          <FontAwesomeIcon icon={faRightFromBracket} onClick={handleLinkClick} />
          <p onClick={handleLinkClick}>Log out</p>
        </a>
      </nav>
    </aside>
    </>
  );
};

export default Sidebar;
