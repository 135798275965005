import React from 'react';
import './Loader.css';  // Ensure to create a corresponding CSS file

const Loader = () => {
  return (
    <div className="wrapper-loader">
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="circle"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
      <div className="shadow"></div>
    </div>
  );
};

export default Loader;
