import React, { useContext,} from "react";
import { useNavigate } from 'react-router-dom';
import configureImage from './configure.webp';
import viewSchoolImage from'./view-school.webp';
import { AuthContext } from '../context/AuthContext';
import "./Schools.css";

const School = () => {

  const navigate = useNavigate();
  const { userDetails } = useContext(AuthContext);

  const functions = [
    {
      title: "Configuration",
      description: "Customize and manage your regions and schools here. Add, edit, or delete settings to ensure everything fits your needs.",
      detailsButton: 'Configure',
      image: configureImage,
      route: '/configure-region-school',
      adminOnly:true,
    },
    {
      title: "View Schools",
      description: "View the list of schools and access their general information here.",
      detailsButton: 'View',
      image: viewSchoolImage,
      route: '/view-schools',
      adminOnly:false,
    },
    
    
    
  ];

  return (
    <div className="school-container">
      <h2>Schools</h2>
      <div className="cards">
        {functions.map((func, index) => (
          (userDetails.role === 'ADMIN'|| !func.adminOnly)&&(
          <div key={index} className="card">
            <div className="card-preview">
              <img src={func.image} alt={func.title} className="card-image" />
            </div>
            <div className="card-right">
              <div className="card-content">
                <h2>{func.title}</h2>
                <p className="description">{func.description}</p>
              </div>
              <div className="card-footer">
                <button className="details-button" onClick={() => navigate(func.route)} >{func.detailsButton}</button>
              </div>
            </div>
          </div>
          )
        ))}
      </div>
    </div>
  );
};

export default School;
