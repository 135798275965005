import React from 'react';
import './Profile.css';

const Profile = () => {
  return (
    <div className='profile'>
      <h2>Profile Page</h2>
      <p>This is the profile page content.</p>
    </div>
  );
};

export default Profile;
