import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ViewSchools.css';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';  // Adjust the path based on your file structure


const API_BASE_URL = process.env.REACT_APP_API_URL;

const ViewSchools = () => {
  const [regions, setRegions] = useState([]);
  const [expandedRegion, setExpandedRegion] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    fetchRegions();
  }, []);

  const fetchRegions = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API_BASE_URL}/regions`);
      const regionsWithSchools = await Promise.all(
        response.data.map(async (region) => {
          const schoolsResponse = await axios.get(`${API_BASE_URL}/schools?region_id=${region.region_id}`);
          return { ...region, schools: schoolsResponse.data };
        })
      );
      setRegions(regionsWithSchools);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching regions:', error);
      setIsLoading(false);
    }
  };

  const toggleRegion = (region_id) => {
    setExpandedRegion(expandedRegion === region_id ? null : region_id);
  };

  const handleSchoolClick = (school_id) => {
    navigate(`/schools/${school_id}`);
  };

  return (
    <div className="view-schools-page">
      <h1>View Schools</h1>
      {isLoading ? (
      <Loader />
    ) : (
      <>
      <div className="region-list">
        <ul className='outer-list'>
          {regions.map((region) => (
            <li key={region.region_id}>
              <div className="region-item">
              <span>{region.region_name}</span>
                <span className="toggle-icon" onClick={() => toggleRegion(region.region_id)}>
                  {expandedRegion === region.region_id ? '▲' : '▼'}
                </span>
              </div>
              {expandedRegion === region.region_id && (
                <ul className="school-listv">
                  {region.schools.map((school) => (
                    <li key={school.school_id} className="school-itemv" onClick={() => handleSchoolClick(school.school_id)}>
                      {school.school_name} 
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
      </>
    )}
    </div>
  );
};

export default ViewSchools;
